.myimage{
    height: min-content;
    width:100% ;
    background-color: black;
    flex:1 ;
    position: relative;

}
.ok{
    margin-top: 30%;
    margin-left: 10%;
    z-index: 1;
}

.image{
    margin-left: 35%;
    height: min-content;
    background-color: black;
   
}
p{
    color: #fff;
    padding-top: 36% ;
    padding-left: 13%;
    position: absolute;
    font-size: 4rem;
}




.span{
    color: #fff;
    padding-top: 34%;
    padding-left: 19%;
    position: absolute;
    font-size: 1.5rem;
    z-index: 1;

}

@media screen and (min-width: 1001px) {
    p{
      font-size: 70px;
      
    }
    .span{
        font-size: 35px;
    }

    .myimage{
        height: min-content;
        width:100%
    }

    .home-text{
        font-size: 25px;
    }
  }
  
  @media screen and (max-width: 1000px) {
    p {
      font-size: 35px;
      margin-top: 25%;
      
    }
    .span{
        font-size: 25px;
    }
    .myimage{
        height: 550px;
        width:100%;
        padding-right:28%;
    }

    .image{
        height:100%;
        margin-right: 20%;
    }

    .home-text{
        font-size: 20px;
    }
  }

  .home-text{
    color: #fff;
    background-color: black;
    word-spacing: 0.1em;
    position: relative;
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;
    margin-bottom: 0%;
    
  }

  .homepage{
    background-color: black;
    height: 100%;
  }

  .home-para{
   margin:0% 7%;
  }
