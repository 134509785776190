nav.imageWrapper{
    position: relative;
}

.navbar{
    font-size: 1rem;
}

#h5{
    color: #fff;
}
.lb, .lb1{
    margin:12%;
    margin-top: 0%;
    margin-bottom: 0%;
}


