.code-page{
    color: #fff;
    background-color: black;
    height: 100%;
}
body {
	margin-top: 80px;
}

table caption {
	padding: .5em 0;
}

.table{
    margin-top: 5%;
    background-color: rgb(68, 68, 68);
}
.freq{
    font-size:14px;
    margin-top: 10%;
}

.i {
    background-size: 2px 7px;
  }

.button{
    font-weight: bold;
}

.button{
    text-decoration: none;
}

.table-text{
    text-decoration: none;
    font-weight: bold;
}

