.personal-text{
    color:black;
    font-size: medium;
    
    
}

.personal{

    background-color: black;
    height: max-content;
    margin-top: 5%;

    
}

.personal-bg{
    margin-top: 3%;
    margin-left: 15%;
    margin-right: 15%;
}






